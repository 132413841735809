import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep, getModelKey, getQueryParameters } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getDigitalTwinField: state => field => state[field],
    getDigitalTwinLayerStyles: state => state.layerStyles,
    getDigitalTwinActiveLayers: state => state.activeLayers,
    getDigitalTwinPTActiveLayers: state => state.ptActiveLayers,
    getDigitalTwinFiltersById: state => id => {
      return state.layerStyles[getModelKey()]?.[id]?.filters || []
    }
  },
  mutations: {
    DIGITAL_TWIN_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    DIGITAL_TWIN_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    },
    DIGITAL_TWIN_ASSIGN(state, { field, key, value }) {
      state[field] = Object.assign({ ...state[field] }, { [key]: value })
    },
    DIGITAL_TWIN_SET_FILTER_PROP(state, pr) {
      const { filterId, action, layerId, prop, value } = pr
      const layerStyles = cloneDeep(state.layerStyles)
      const { filters } = layerStyles[getModelKey()][layerId]
      const index = filters.findIndex(({ id }) => id === filterId)

      if (action === 'delete') {
        filters.splice(index, 1)
      } else if (!filters[index]) {
        console.warn('filter not found!')
      } else if (prop && value !== undefined) {
        filters[index] = {
          ...filters[index],
          [prop]: value
        }
      }

      state.layerStyles = layerStyles
    },
    DIGITAL_TWIN_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    DIGITAL_TWIN_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)

      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
